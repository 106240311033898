import WebServiceRequest from "./WebServiceRequest";

class GetUserLevelsList extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("UserLevel/GetList");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class CreateNewLevel extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("UserLevel/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class UpdateALevel extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("UserLevel/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class DeleteALevel extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("UserLevel/Delete");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

export { GetUserLevelsList, CreateNewLevel, UpdateALevel, DeleteALevel };
