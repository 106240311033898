<template>
  <b-modal
    title="Create a new level"
    id="create-new-level"
    centered
    ok-title="Create"
    cancel-title="Dismiss"
    :ok-disabled="isLoading"
    no-close-on-backdrop
    ok-variant="success"
    no-close-on-ok
    @hidden="resetModal"
    @ok.prevent="okCallback(userLevelPayload)"
  >
    <b-overlay :show="isLoading">
      <b-container fluid>
        <b-form-row>
          <b-col cols="12">
            <text-input
              v-model="userLevelPayload.title"
              inputId="levelTitle"
              inputLabel="Level Title"
              inputPlaceholder="Gold"
            ></text-input>
          </b-col>
          <b-col cols="12">
            <text-input
              v-model="userLevelPayload.fromPrice"
              inputId="levelFromPrice"
              inputLabel="Level Start Price (From)"
              inputPlaceholder="Enter your level start price"
            ></text-input>
          </b-col>
          <b-col cols="12">
            <text-input
              v-model="userLevelPayload.toPrice"
              inputId="levelToPrice"
              inputLabel="Level End Price (To)"
              inputPlaceholder="Enter your level end price"
            ></text-input>
          </b-col>
        </b-form-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>
<script>
export default {
  props: {
    okCallback: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      userLevelPayload: {
        id: null,
        title: null,
        fromPrice: null,
        toPrice: null,
      },
    };
  },
  methods: {
    resetModal() {
      this.userLevelPayload = {
        id: null,
        title: null,
        fromPrice: null,
        toPrice: null,
      };
    },
  },
  components: {
    BModal,
    BOverlay,
    BContainer,
    BCol,
    BFormRow,
    BFormGroup,
    BButton,
    TextInput,
  },
};
import TextInput from "@/views/components/Utilities/TextInput.vue";
import {
  BModal,
  BOverlay,
  BContainer,
  BCol,
  BFormRow,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
</script>
