<template>
  <page-wrapper
    :isLoading="isLoading"
    :hasFilters="true"
    @submitFilters="getAllUserLevels"
  >
    <template #title>User Levels</template>
    <template #titleBtn>
      <b-button variant="success" @click="renderLevelCreateModal">
        Create New User Level
      </b-button>
    </template>
    <template #filters>
      <search-input
        @enter="getAllUserLevels"
        v-model="userLevelFilters.title"
        inputId="titleFilter"
        inputLabel="Search By Level Title"
        inputPlaceholder="Bronze"
      ></search-input>
      <search-input
        @enter="getAllUserLevels"
        v-model="userLevelFilters.fromPrice"
        inputId="fromPriceFilter"
        inputLabel="Search By Start Price"
        inputPlaceholder="20"
      ></search-input>
      <search-input
        @enter="getAllUserLevels"
        v-model="userLevelFilters.toPrice"
        inputId="ftoPriceFilter"
        inputLabel="Search By End Price"
        inputPlaceholder="200"
      ></search-input>
    </template>
    <custom-table
      :pageNumber="userLevelFilters.pageNumber"
      :count="userLevelFilters.count"
      :totalCount="totalCount"
      :tableItems="userLevels"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
      <template #actions="data">
        <div class="d-flex align-items-center justify-content-between">
          <b-button
            type="button"
            variant="transparent"
            size="sm"
            @click="renderUpdateModal(data.item)"
          >
            <feather-icon icon="EditIcon" class="text-primary" size="20" />
          </b-button>
          <b-button
            type="button"
            variant="transparent"
            size="sm"
            @click="renderDeleteModal(data.item)"
          >
            <feather-icon icon="Trash2Icon" class="text-danger" size="20" />
          </b-button></div
      ></template>
    </custom-table>
    <user-level-create-modal
      :isLoading="isLoading"
      :okCallback="createANewUserLevel"
    ></user-level-create-modal>
    <user-level-update-modal
      :isLoading="isLoading"
      :tempLevel="tempLevel"
      :okCallback="updateAUserLevel"
    ></user-level-update-modal>
    <user-level-delete-modal
      :isLoading="isLoading"
      :tempLevel="tempLevel"
      :okCallback="deleteAUserLevel"
    ></user-level-delete-modal>
  </page-wrapper>
</template>

<script>
export default {
  title: "User Levels List",
  mounted() {
    this.getAllUserLevels();
  },
  data() {
    return {
      isLoading: false,
      userLevelFilters: {
        pageNumber: 1,
        count: 10,
        id: null,
        title: null,
        fromPrice: null,
        toPrice: null,
      },
      totalCount: null,
      userLevels: null,
      myTableColumns: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "title",
          label: "Title",
        },
        {
          key: "fromPrice",
          label: "Start Price",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "toPrice",
          label: "End Price",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      tempLevel: null,
    };
  },
  methods: {
    renderLevelCreateModal() {
      this.$bvModal.show("create-new-level");
    },
    renderUpdateModal(level) {
      this.tempLevel = JSON.parse(JSON.stringify(level));
      this.$bvModal.show("update-a-level");
    },
    renderDeleteModal(level) {
      this.tempLevel = JSON.parse(JSON.stringify(level));
      this.$bvModal.show("delete-a-level-confimration-modal");
    },
    changePage(pageNumber) {
      this.userLevelFilters.pageNumber = pageNumber;
      this.getAllUserLevels();
    },
    async deleteAUserLevel() {
      try {
        this.isLoading = true;
        let _this = this;
        let deleteALevel = new DeleteALevel(_this);
        deleteALevel.setRequestParam({
          id: _this.tempLevel.id,
        });
        await deleteALevel.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("delete-a-level-confimration-modal");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Deleted!",
                icon: "CheckIcon",
                variant: "success",
                text: "Level Deleted successfully.",
              },
            });
            _this.getAllUserLevels();
            _this.tempLevel = null;
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async updateAUserLevel(payload) {
      try {
        this.isLoading = true;
        let _this = this;
        let updateALevel = new UpdateALevel(_this);
        updateALevel.setRequestParamDataObj(payload);
        await updateALevel.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("update-a-level");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Updated!",
                icon: "CheckIcon",
                variant: "success",
                text: "Level Updated successfully.",
              },
            });
            _this.getAllUserLevels();
            _this.tempLevel = null;
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createANewUserLevel(payload) {
      try {
        this.isLoading = true;
        let _this = this;
        let createNewLevel = new CreateNewLevel(_this);
        createNewLevel.setRequestParamDataObj(payload);
        await createNewLevel.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("create-new-level");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Created!",
                icon: "CheckIcon",
                variant: "success",
                text: "Level Created successfully.",
              },
            });
            _this.getAllUserLevels();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getAllUserLevels() {
      try {
        this.isLoading = true;
        let _this = this;
        let getUserLevelsList = new GetUserLevelsList(_this);
        getUserLevelsList.setRequestParamDataObj(_this.userLevelFilters);
        await getUserLevelsList.fetch((res) => {
          _this.totalCount = res.total;
          _this.userLevels = res.data;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    PageWrapper,
    SearchInput,
    BButton,
    CustomTable,
    UserLevelCreateModal,
    UserLevelUpdateModal,
    UserLevelDeleteModal,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import UserLevelCreateModal from "@/views/components/UserLevels/UserLevelCreateModal.vue";
import UserLevelUpdateModal from "@/views/components/UserLevels/UserLevelUpdateModal.vue";
import UserLevelDeleteModal from "@/views/components/UserLevels/UserLevelDeleteModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { BButton } from "bootstrap-vue";
import {
  GetUserLevelsList,
  CreateNewLevel,
  UpdateALevel,
  DeleteALevel,
} from "@/libs/Api/UserLevel";
</script>
